@import 'src/scss/scss';

$animationDuration: 0.25s;

.container {
  display: flex;
  position: fixed;
  z-index: 11;
  right: $padding / 2;
  bottom: $padding / 2;
  left: $padding / 2;
  align-items: center;
  justify-content: center;

  transition: $animationDuration ease;
  transition-property: right, bottom, left;

  animation-name: fadein;
  animation-duration: $animationDuration;
  animation-iteration-count: 1;

  pointer-events: none;

  @include media-breakpoint-down(sm) {
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.removing {
  animation-name: fadeout;
}

.toast {
  display: grid;
  grid-template-areas: 'icon message progress controls';
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1000px + $padding;

  padding: $padding;

  transition: border-radius $animationDuration ease;

  border-radius: 2px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

  pointer-events: initial;

  @include media-breakpoint-down(sm) {
    border-radius: 0;
  }
}

.icon {
  grid-area: icon;

  margin-right: $padding;
}

.message {
  grid-area: message;
  flex: 1 1 auto;

  max-height: 100px;

  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
}

.progress {
  grid-area: progress;

  height: 1em;
}

.buttons {
  grid-area: controls;
  margin-left: $padding;
}

.closer {
  grid-area: controls;
  margin-left: $padding;

  cursor: pointer;
}

.primary {
  background-color: var(--primary-colour);
  color: var(--primary-colour-copy);

  a {
    color: var(--primary-colour-copy);
  }
}

.secondary {
  background-color: var(--secondary-colour);
  color: var(--secondary-colour-copy);

  a {
    color: var(--secondary-colour-copy);
  }
}

.positive {
  background-color: var(--positive-colour);
  color: var(--positive-colour-copy);

  a {
    color: var(--positive-colour-copy);
  }
}

.warning {
  background-color: var(--warning-colour);
  color: var(--warning-colour-copy);

  a {
    color: var(--warning-colour-copy);
  }
}

.negative {
  background-color: var(--danger-colour);
  color: var(--danger-colour-copy);

  a {
    color: var(--danger-colour-copy);
  }
}

.action {
  background-color: var(--attention-colour);
  color: var(--attention-colour-copy);

  a {
    color: var(--attention-colour-copy);
  }
}

.development {
  $colour1: var(--toast-development-colour-1);
  $colour2: var(--toast-development-colour-2);
  $stripe-size: 20px;

  background: repeating-linear-gradient(
    45deg,
    $colour1,
    $colour1 $stripe-size,
    $colour2 $stripe-size,
    $colour2 $stripe-size * 2
  );

  .message {
    text-shadow: 0 0 3px black;
  }
}

@keyframes fadein {
  0% {
    transform: translateY(48px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(48px);
    opacity: 0;
  }
}

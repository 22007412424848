@import 'src/scss/scss';

@import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap');

/* Define the "system" font family */
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
    local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'),
    local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}

html,
body {
  box-sizing: border-box;
  height: 100%;

  margin: 0;

  color: var(--light-copy-colour);

  font-family: 'Titillium Web', 'system', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// stylelint-disable-next-line selector-max-specificity
#root {
  height: 100%;
}

:root {
  --light-copy-colour: #4d4d4f;
  --dark-copy-colour: #ffffff;
  --light-background-colour: #ffffff;
  --dark-background-colour: #4d4d4f;

  --primary-colour: #26a0da;
  --primary-colour-copy: #ffffff;
  --secondary-colour: #314755;
  --secondary-colour-copy: #ffffff;
  --positive-colour: #00e99f;
  --positive-colour-copy: #ffffff;
  --warning-colour: #ffdd06;
  --warning-colour-copy: #4d4d4f;
  --danger-colour: #ff465c;
  --danger-colour-copy: #ffffff;
  --attention-colour: #ee7417;
  --attention-colour-copy: #ffffff;

  --dark-grey-colour: #363531;
  --dark-grey-colour-copy: #ffffff;
  --light-grey-colour: #dadada;
  --light-grey-colour-copy: #4d4d4f;
  --lighter-grey-colour: #efefef;
  --lighter-grey-colour-copy: #4d4d4f;
  --off-white-colour: #efefef;
  --off-white-colour-copy: #4d4d4f;

  --main-copy-colour: var(--light-copy-colour);
  --main-background-colour: var(--light-background-colour);
  --main-off-copy-colour: var(--light-copy-colour);

  &[data-theme='dark'],
  &[data-theme='black'] {
    --main-copy-colour: var(--dark-copy-colour);
    --main-background-colour: var(--dark-background-colour);
    --main-off-copy-colour: var(--dark-copy-colour);
  }
}
